<template>
  <div>
    <EStoreFetchError
      v-if="isError"
      :message="t('fetch_failed', { data: t('offers_group') })"
    />

    <div v-else-if="offersGroup?.length" class="px-4 mt-4">
      <div class="relative overflow-hidden">
        <EStoreSwiper
          id="offer-group-slider"
          swiper-pagination
          :title="t('offers_group')"
          :items="offersGroup"
          pagination-position="top-4"
        >
          <template #slide="{ item }">
            <div class="h-8 sm:h-12"></div>
            <HomeOffersGroupSlide :offer="item" />
          </template>
        </EStoreSwiper>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { GroupOffer } from '~~/composables/useMenuModel'
const { groupOfferModel } = useMenuModel()
const props = withDefaults(
  defineProps<{
    query?: object
  }>(),
  {
    query: {} as any
  }
)

const offersGroup = ref<GroupOffer[] | null>(null)
const isError = ref(false)
const { t } = useI18n()
if (!offersGroup.value?.length) {
  const { data, error } = await useBasicFetch(
    getAllRoutes().materialsRoutes.materialGroupOfferByServer,
    {
      query: { ...props.query, limit: 1000 },

      transform: (data: SuccessResponse<GroupOffer[]>) => {
        return data.data.filter((el) => el.is_active === 1).map(groupOfferModel)
      }
    }
  )
  if (error.value != null) {
    isError.value = true
  }
  if (data.value != null) {
    offersGroup.value = data.value
  }
}
</script>

<style>
.swiper-pagination-bullet {
  @apply bg-text_color bg-opacity-50 h-2 md:h-3 mb-1 w-2 md:w-3 rounded-full !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-nav_color w-7 md:w-14  !important;
}
#offer-group-slider > .swiper > .swiper-pagination.main-pagination {
  @apply top-auto bottom-2 flex overflow-hidden items-center justify-center;
}
</style>
